import React from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

function BlogPostCardTitle({
  children,
  ...rest
}: React.PropsWithChildren<TypographyProps>) {
  return (
    <Typography
      style={{ fontFamily: 'Open Sans', fontSize: '1rem' }}
      variant="h6"
      gutterBottom
      {...rest}
    >
      {children}
    </Typography>
  )
}

export default BlogPostCardTitle
