import React from 'react'
import Typography, { TypographyProps } from '@mui/material/Typography'

function BlogPostCardDescription({
  children,
  ...rest
}: React.PropsWithChildren<TypographyProps>) {
  return (
    <Typography
      style={{ fontSize: '0.9rem' }}
      variant="subtitle1"
      paragraph
      {...rest}
    >
      {typeof children === 'string' && children.length > 180
        ? children.substring(0, 177)
        : children}
    </Typography>
  )
}

export default BlogPostCardDescription
