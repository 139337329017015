import React from 'react'
import { css } from '@emotion/react'
import CardContent from '@mui/material/CardContent'
import useTheme from '@mui/material/styles/useTheme'
import { BlogPost } from 'app/types'
import Link from 'components/common/Link'
import CardRoot, { CardProps as CardRootProps } from '../Card'
import BlogPostCardTitle from './BlogPostCardTitle'
import BlogPostCardDescription from './BlogPostCardDescription'
import BlogPostCardTags from './BlogPostCardTags'
import BlogPostCardFooter from './BlogPostCardFooter'

export interface BlogPostCardProps extends BlogPost, CardRootProps {
  component?: React.ElementType<any>
  card?: CardRootProps
  footer?: React.ReactNode
  showReadMore?: boolean
}

function BlogPostCard(props: BlogPostCardProps) {
  const {
    component: Component = CardRoot,
    title = '',
    description = '',
    url = '',
    media = '',
    date = '',
    lastUpdated = '',
    author = '',
    tags = [],
    category = '',
    footer,
    showReadMore = true,
    transparent,
  } = props

  const theme = useTheme()

  const tagItems =
    tags.length > 3 ? [...tags.slice(0, 2), `+ ${tags.length - 3} more`] : tags

  return (
    <Component
      css={css`
        width: 100%;
        ${theme.breakpoints.down('xs')} {
          text-align: center;
        }
      `}
      transparent={transparent}
    >
      <div
        css={css`
          display: flex;
          flex-wrap: nowrap;
          width: 100%;
          height: 100%;
        `}
      >
        {media && (
          <div
            css={css`
              width: 100%;
              max-width: 250px;
              display: flex;
              align-items: center;
            `}
          >
            <Link to={url}>
              <img
                css={css`
                  width: 100%;
                  height: 100%;
                  ${theme.breakpoints.down('md')} {
                    max-height: 400px;
                  }
                  ${theme.breakpoints.down('sm')} {
                    max-height: 300px;
                  }
                  border-radius: 4px;
                  object-fit: cover;
                `}
                src={media as string}
                title={title}
              />
            </Link>
          </div>
        )}
        {(title || description) && (
          <CardContent style={{ width: '100%' }}>
            <Link to={url}>
              <BlogPostCardTitle>{title}</BlogPostCardTitle>
            </Link>
            <BlogPostCardDescription>{description}</BlogPostCardDescription>
            <BlogPostCardTags tags={tagItems} />
            <div style={{ height: 15 }} />
            {footer && <BlogPostCardFooter>{footer}</BlogPostCardFooter>}
          </CardContent>
        )}
      </div>
    </Component>
  )
}

BlogPostCard.Footer = BlogPostCardFooter

export default BlogPostCard
