import React from 'react'
import { css } from '@emotion/react'
import Typography from '@mui/material/Typography'

export interface BlogPostCardFooterProps {
  className?: string
}

function BlogPostCardFooter({
  children,
  className,
  ...rest
}: React.PropsWithChildren<BlogPostCardFooterProps>) {
  return (
    <div
      className={className}
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
      {...rest}
    >
      {children}
    </div>
  )
}

BlogPostCardFooter.Left = function ({
  children,
  ...rest
}: React.PropsWithChildren<any>) {
  return <div {...rest}>{children}</div>
}

BlogPostCardFooter.Center = function ({
  children,
  ...rest
}: React.PropsWithChildren<any>) {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
      {...rest}
    >
      {children}
    </div>
  )
}

BlogPostCardFooter.Right = function ({
  children = '',
}: React.PropsWithChildren<any>) {
  return children ? (
    <Typography style={{ height: '100%', marginRight: 15 }} variant="subtitle2">
      {children}
    </Typography>
  ) : null
}

export default BlogPostCardFooter
