import React from 'react'
import MuiCard, { CardProps as MuiCardProps } from '@mui/material/Card'
import Fade from '@mui/material/Fade'
import { RenderProp } from 'app/types'

export interface CardProps extends MuiCardProps {
  media?: RenderProp | string
  padding?: number
  transparent?: boolean
}

function Card(props: CardProps) {
  const { children, media, transparent, ...rest } = props

  return (
    <Fade timeout={500} in>
      <MuiCard
        style={{
          boxShadow: transparent
            ? 'none'
            : '-3px 0 22px 8px rgba(0, 0, 0, 0.15)',
          background: transparent ? 'none' : undefined,
          boxSizing: 'border-box',
          minHeight: 200,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
        raised={!transparent}
        elevation={transparent ? 0 : 4}
        {...rest}
      >
        {children}
      </MuiCard>
    </Fade>
  )
}

export default Card
