import React from 'react'
import * as u from '@jsmanifest/utils'
import { Link } from 'gatsby'
import kebabCase from 'lodash/kebabCase'
import Chip, { ChipProps } from '@mui/material/Chip'

export type BlogPostCardTagsProps = React.HTMLAttributes<HTMLDivElement> & {
  tags?: string[]
  tagProps?: ChipProps
}

function Tag({ tag, ...rest }: { tag: string } & ChipProps) {
  return (
    <Chip
      key={tag}
      style={{ margin: 2 }}
      label={tag}
      size="small"
      disabled={u.isStr(tag) && tag.startsWith('+ ')}
      clickable
      {...rest}
    />
  )
}

function BlogPostCardTags({
  tags = [],
  tagProps,
  ...rest
}: BlogPostCardTagsProps) {
  return (
    <div style={{ margin: '2px !important' }} {...rest}>
      {tags.map((tag) => {
        const isDisabled = u.isStr(tag) && tag.startsWith('+ ')
        if (isDisabled) return <Tag tag={tag} {...tagProps} />
        return (
          <Link key={tag} to={`/tags/${kebabCase(tag)}`}>
            <Tag tag={tag} {...tagProps} />
          </Link>
        )
      })}
    </div>
  )
}

export default BlogPostCardTags
